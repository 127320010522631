import { FC } from "react";

interface Props {
    title?: string;
    text?: string;
    image?: string;
}

export const MissionSection: FC<Props> = (props) => {
    return (
        <div className="max-w-[320px] md:max-w-[800px] w-full flex flex-col items-center justify-center p-6 bg-[#E2EBFF] rounded-lg mt-[40px]">
            <div className="flex flex-col items-center justify-center gap-4">
                <div className="text-sm font-medium text-center px-3 py-1 rounded-full bg-[#2764EB] text-[#ffffff]">
                    {props.title}
                </div>
                <p className="text-xl text-center italic font-bold leading-8 text-[#24234C]">
                    {props.text}
                </p>
            </div>
            <img src={props.image} className="mt-6 w-10 h-10" />
        </div>
    );
};

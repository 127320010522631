import { Trans } from "next-i18next";
import { FC } from "react";
import AccentText from "./AccentText";

interface Props {
    title?: string;
    benefits?: {
        title: string;
        description: string;
        image: string;
        bgColor: string;
    }[];
}

export const BenefitsSection: FC<Props> = ({ title, benefits }) => {
    return (
        <div className="max-w-[320px] md:max-w-[800px]">
            <div className="flex flex-col gap-4">
                <p className="text-2xl text-center font-bold">
                    <Trans
                        components={{
                            AccentText: <AccentText color="#2764EB" />,
                        }}
                    >
                        {title}
                    </Trans>
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-4 mt-8">
                {benefits?.map((benefit, index) => {
                    return (
                        <div
                            key={index}
                            className="flex flex-col flex-1 gap-4 rounded-lg pb-8 overflow-hidden bg-[#E2EBFF]"
                        >
                            <img src={benefit.image} className="" />
                            <div className="flex flex-col gap-4 px-4">
                                <p className="text-xl text-start font-bold">
                                    {benefit.title}
                                </p>
                                <p className="text-start text-base text-[#24234C]">
                                    {benefit.description}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

import { currencyByCountry, currencySign } from "@/src/shared/types/currency";
import { FALLBACK_GEO, IGeo, IGeoUpdated } from "@/src/shared/types/geo";
import http from "@/src/shared/utils/http";

export const getGeo = async () => {
    return await http
        .get<IGeo>(process.env.NEXT_PUBLIC_GEO_API_URL as string)
        .then((response) => {
            const geo = response.data;

            const updateData = {
                currency: "USD",
                currencySign: "$",
                jurisdiction: "",
            };

            if (!!currencyByCountry[geo.id]) {
                updateData.currency = currencyByCountry[geo.id];
                updateData.currencySign = currencySign[updateData.currency];
            }

            if (geo.alpha_2 === "US") {
                updateData.jurisdiction = "ARNEGEN";
            } else if (geo.alpha_2 === "HK" || geo.alpha_2 == "SG") {
                updateData.jurisdiction = "HONGKONG_HARBOUR";
            } else if (geo.groups.includes("legal_scorpios")) {
                updateData.jurisdiction = "SCORPIOS";
            } else {
                updateData.jurisdiction = "ZIMRAN";
            }

            return { ...updateData, ...geo } as IGeoUpdated;
        })
        .catch(() => FALLBACK_GEO);
};

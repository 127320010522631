export const currencySign: any = {
    USD: "$",
    EUR: "€",
    GBP: "£",
};
export const currencyByCountry: any = {
    BEL: "EUR",
    CYP: "EUR",
    DEU: "EUR",
    EST: "EUR",
    GRC: "EUR",
    IRL: "EUR",
    LTU: "EUR",
    LUX: "EUR",
    LVA: "EUR",
    MLT: "EUR",
    NLD: "EUR",
    SVK: "EUR",
    AUT: "EUR",
    ITA: "EUR",
    FRA: "EUR",
    FIN: "EUR",
    PRT: "EUR",
    ESP: "EUR",
    SVN: "EUR",
    GBR: "GBP",
};

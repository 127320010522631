import { FC } from "react";

interface Props {
    color?: string;
    children?: JSX.Element | JSX.Element[] | string;
}

const AccentText: FC<Props> = ({ color, children }) => {
    return <span style={{ color: color || "#000000" }}>{children}</span>;
};

export default AccentText;

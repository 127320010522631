import { Trans, useTranslation } from "next-i18next";
import { FC } from "react";

interface Props {
    image?: string;
    text?: string;
    icon?: string;
}

export const SocialProofSection: FC<Props> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="max-w-[320px] mt-10 w-full flex flex-col items-center justify-center">
            <img src={props.image} />
            <div className="w-full flex items-center justify-center gap-2 bg-gray-50 py-4 rounded-lg mt-10">
                <img width={20} height={20} src={props.icon} />
                <p className="text-base">
                    <Trans
                        t={t}
                        components={{
                            b: <b />,
                        }}
                    >
                        {props.text}
                    </Trans>
                </p>
            </div>
        </div>
    );
};

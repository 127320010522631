import { FC } from "react";

interface Props {
    title?: string;
    subtitle?: string;
    image?: string;
}

export const SkillsSection: FC<Props> = (props) => {
    return (
        <div className="flex flex-col items-center justify-center max-w-[320px] mt-[58px]">
            <div className="flex flex-col gap-4">
                <p className="text-2xl text-center font-bold">{props.title}</p>
                <p className="text-[30px] text-center font-bold text-[#2764EB]">
                    {props.subtitle}
                </p>
            </div>
            <img src={props.image} className="mt-6" />
        </div>
    );
};

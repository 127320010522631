import axios from "axios";

const config = {
    headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_FUNNEL_TOKEN}`,
    },
};
const paymentsConfig = {
    headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_FUNNEL_TOKEN}`,
        "Tenant-Id": `${process.env.NEXT_PUBLIC_APPLE_PAY_TENANT_ID}`,
    },
};

const get = async <T = any>(endpoint: string, headers?: any) => {
    return axios.get<T>(endpoint, {
        headers: { ...config.headers, ...headers },
    });
};

const post = async <T = any, D = any>(endpoint: string, data: D) => {
    return await axios.post<T>(endpoint, data, config);
};
const paymentsPost = async <T = any, D = any>(endpoint: string, data: D) => {
    return await axios.post<T>(endpoint, data, paymentsConfig);
};

const put = async <T = any, D = any>(endpoint: string, data: D) => {
    return await axios.put<T>(endpoint, data, config);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { get, post, put, paymentsPost };

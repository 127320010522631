import { BenefitsSection } from "@/components/landing/BenefitsSection";
import { CTASection } from "@/components/landing/CTASection";
import CardCTASection from "@/components/landing/CardCTASection";
import Gradient from "@/components/landing/Gradient";
import LandingHeader from "@/components/landing/Header";
import LegalSection from "@/components/landing/LegalSection";

import { MissionSection } from "@/components/landing/MissionSection";
import Sidebar from "@/components/landing/Sidebar";
import { SkillsSection } from "@/components/landing/SkillsSection";
import { SocialProofSection } from "@/components/landing/SocialProofSection";
import useAnalytics from "@/src/shared/hooks/analytics";
import { getLandingPageContent } from "@/src/shared/utils/content/content";

import { GetServerSidePropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";

export const getServerSideProps = async (
    context: GetServerSidePropsContext
) => {
    const content = await getLandingPageContent("personal-finance-finelo");

    return {
        props: {
            content,
            ...(await serverSideTranslations(context.locale || "en", [
                "common",
            ])),
        },
    };
};

const Landing: FC<{ content: any }> = ({ content }) => {
    const router = useRouter();
    const analytics = useAnalytics();

    useEffect(() => {
        if (!content) router.push("/");
        analytics.trackEvent("pr_prspri_landing_view");
    }, []);

    if (!content) return null;

    return (
        <>
            <Sidebar {...content.Sidebar} />
            <div className="flex flex-col items-center relative">
                <Gradient />
                <LandingHeader {...content.LandingHeader} />
                <CTASection {...content.CTASection} />
                <SocialProofSection {...content.SocialProofSection} />
                <MissionSection {...content.MissionSection} />
                <SkillsSection {...content.SkillsSection} />
                <BenefitsSection {...content.BenefitsSection} />
                <CardCTASection {...content.CardCTASection} />
                <LegalSection {...content.LegalSection} />
            </div>
        </>
    );
};

export default Landing;

/* eslint-disable prefer-promise-reject-errors */
import { selector } from "recoil";
import { IGeoUpdated } from "@/src/shared/types/geo";
import { getGeo } from "@/src/shared/hooks/geo/api";

export const GeoQueryState = selector<IGeoUpdated>({
    key: "GeoQueryState",
    get: async () => {
        return await getGeo();
    },
});

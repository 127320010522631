import { Trans } from "next-i18next";
import { FC } from "react";
import AccentText from "./AccentText";
import { useRouter } from "next/router";
import useAnalytics from "@/src/shared/hooks/analytics";

interface Props {
    title?: string;
    subtitle?: string;
    ctaText?: string;
    link?: string;
}

export const CTASection: FC<Props> = (props) => {
    const router = useRouter();
    const analytics = useAnalytics();

    const handleClick = () => {
        analytics.trackEvent("pr_prspri_landing_start_quiz");
        router.push(
            "https://quiz.finelo.com/v3?utm_source=finelo_landing&utm_medium=cta"
        );
    };

    return (
        <div className="max-w-[320px] flex flex-col gap-2 justify-center items-center mt-10">
            <p className="text-4xl font-bold text-center">
                <Trans
                    components={{
                        AccentText: <AccentText color="#2764EB" />,
                    }}
                >
                    {props.title}
                </Trans>
            </p>
            <p className="text-lg font-normal text-center">{props.subtitle}</p>
            <button
                onClick={handleClick}
                className="px-12 py-3 mt-6  min-h-[48px] rounded-lg bg-[#2764EB] text-white font-semibold"
            >
                {props.ctaText}
            </button>
        </div>
    );
};

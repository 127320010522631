import { showSidebarState } from "@/src/shared/hooks/sidebar/recoil";
import Image from "next/image";
import Link from "next/link";
import { FC, useEffect } from "react";
import { useRecoilState } from "recoil";
import CrossIcon from "@/public/assets/icons/icon-cross.svg";

interface Props {
    links: {
        text: string;
        url: string;
    }[];
    supportEmail: {
        text: string;
        url: string;
    };
}

const Sidebar: FC<Props> = ({ links, supportEmail }) => {
    const [showSidebar, setShowSidebar] = useRecoilState(showSidebarState);

    const handleCloseSidebar = () => {
        setShowSidebar(false);
        document.body.style.overflow = "auto";
    };

    useEffect(() => {
        if (showSidebar) window.scrollTo(0, 0);
    }, [showSidebar]);

    if (!showSidebar) return null;

    document.body.style.overflow = "hidden";

    return (
        <div className="absolute z-10 h-full mx-auto inset-x-0 lg:w-full">
            <div className="relative h-full flex">
                <div className="w-[10%] min-[1024px]:w-0 bg-[#000000] bg-opacity-50 "></div>
                <div className="w-[90%] min-[1024px]:w-[100%] bg-white flex-col p-6 min-[1024px]:p-12 surface-primary">
                    <div className="flex flex-col items-end">
                        <CrossIcon
                            width={24}
                            height={24}
                            onClick={handleCloseSidebar}
                            decoding="async"
                        />
                    </div>

                    <div className="flex-col mt-[65px] label-text-primary">
                        <div className="flex flex-col text-lg font-medium mb-12 gap-4">
                            {links.map((link) => {
                                return (
                                    <Link
                                        href={link.url}
                                        key={link.text}
                                        className="text-lg font-normal"
                                    >
                                        {link.text}
                                    </Link>
                                );
                            })}
                        </div>

                        <hr className="mb-6" />
                        <Link
                            href={'mailto:support@finelo.com'}
                            className="text-lg font-normal"
                        >
                            support@finelo.com
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;

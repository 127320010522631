export interface IGeo {
    id: string;
    groups: string[];
    country_name: string;
    alpha_2: string;
    numeric_repr: string;
    ip_address: string;
    cloudfront_headers: {
        HTTP_CLOUDFRONT_VIEWER_COUNTRY: string;
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_NAME: string;
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_REGION: string;
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_REGION_NAME: string;
        HTTP_CLOUDFRONT_VIEWER_CITY: string;
        HTTP_CLOUDFRONT_VIEWER_TIME_ZONE: string;
        HTTP_CLOUDFRONT_VIEWER_LATITUDE: string;
        HTTP_CLOUDFRONT_VIEWER_LONGITUDE: string;
    };
}

export interface IGeoUpdated extends IGeo {
    currency: string;
    currencySign: string;
    jurisdiction: string;
}

//TODO: Думал fallback в целом не уместен. Нужно как то иначе это обрабатывать
export const FALLBACK_GEO = {
    id: "DFT",
    groups: [],
    country_name: "Default",
    alpha_2: "DF",
    numeric_repr: "",
    currency: "USD",
    currencySign: "$",
    jurisdiction: "",
    ip_address: "",
    cloudfront_headers: {
        HTTP_CLOUDFRONT_VIEWER_COUNTRY: "",
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_NAME: "",
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_REGION: "",
        HTTP_CLOUDFRONT_VIEWER_COUNTRY_REGION_NAME: "",
        HTTP_CLOUDFRONT_VIEWER_CITY: "",
        HTTP_CLOUDFRONT_VIEWER_TIME_ZONE: "",
        HTTP_CLOUDFRONT_VIEWER_LATITUDE: "",
        HTTP_CLOUDFRONT_VIEWER_LONGITUDE: "",
    },
};

export const eliteCountriesCode = ["US", "CA", "AU"];
export const subEliteCountriesCode = [
    "GB",
    "FR",
    "DE",
    "NZ",
    "AE",
    "IT",
    "IL",
    "SG",
    "HK",
];

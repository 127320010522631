import { FC } from "react";
import useAnalytics from "@/src/shared/hooks/analytics";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";
import { showSidebarState } from "@/src/shared/hooks/sidebar/recoil";
import MenuIcon from "@/public/assets/icons/menu.svg";
import LogoIcon from "@/public/assets/icons/finelo-logo.svg";

interface Props {
    rightText?: string;
}

const LandingHeader: FC<Props> = ({ rightText }) => {
    const amplitude = useAnalytics();
    const router = useRouter();
    const setShowSidebar = useSetRecoilState(showSidebarState);

    const handleClick = () => {
        amplitude.trackEvent("pr_funnel_login_click", {
            location: router.pathname,
        });
        if (typeof window !== "undefined") {
            window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/auth/signin`;
        }
    };

    return (
        <div className="pt-[22px] pb-4 w-full">
            <div className="flex flex-row gap-2 items-center justify-between w-full px-6 relative">
                <LogoIcon
                    alt="Finelo"
                    width={134}
                    height={32}
                    decoding={"async"}
                />

                <div className="flex items-center justify-end flex-1 flex-row">
                    <MenuIcon
                        width={24}
                        height={24}
                        onClick={() => setShowSidebar(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LandingHeader;

import { useGeo } from "@/src/shared/hooks/geo";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";

interface Props {
    phoneNumberMap: {
        [key: string]: string;
    };
}

const LegalSection: FC<Props> = ({ phoneNumberMap }) => {
    const [jurisdiction, setJurisdiction] = useState("");
    const { t } = useTranslation("common");
    const geo = useGeo();
    const year = new Date().getFullYear();

    const getJurisdictionValue = async () => {
        const geoData = await geo;
        const localizedJurisdiction = t(
            `jurisdictions.main.${geoData.jurisdiction}`,
            { year }
        );
        setJurisdiction(localizedJurisdiction);
    };

    useEffect(() => {
        getJurisdictionValue();
    }, []);

    return (
        <p
            className="text-[#536471] text-center mt-3 min-h-[24px] pt-8 pb-6"
            style={{ fontSize: "10px", fontWeight: "400" }}
        >
            {jurisdiction}
            <br />
            {phoneNumberMap[jurisdiction] || ""}
        </p>
    );
};

export default LegalSection;
